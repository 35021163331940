<template>
  <div class="open-blind-box-record-container">
    <div
      class="list"
      v-if="list.length"
    >
      <div
        class="item row-start"
        v-for="(i, j) in list"
        :key="j"
        @click="toDetail(i)"
      >
        <div class="item-icon">
          <span class="item-icon-container">
            <img
              class="item-icon-container-img"
              :src="i.image"
            />
          </span>
        </div>
        <div class="infos">
          <div class="name">{{i.boxSkuName}}</div>
          <p class="boxNo">编号:{{ i.uniqueCode }}</p>
          <p class="openTime">抽取时间:{{ i.openTime }}</p>
          <button class="detailButton">查看抽中藏品</button>
        </div>
      </div>
    </div>
    <div
      class="epmty"
      v-else
    >
      <img
        src="../assets/icon/composite/empty.png"
        alt=""
      >
      <p>暂无数据</p>
    </div>
    <div
      v-if="showBoxDetail"
      :class="windowHeight <= 560 ? 'middle-mobile-bg' : 'nomal-mobile-bg'"
      style="width: 90vw;height: 438px;border-radius: 8px;background: rgba(0, 0, 0, 0.1);backdrop-filter: blur(10px);z-index:10002;"
    >
      <div
        class="col-center"
        style="width: 90vw;height: 438px;z-index:10003;"
      >
        <div
          style="z-index:10003;"
          class="blind-img-box row-center"
        >
          <div :style="'width: 236px;height: 236px;background-image: url('+ selectItem.relatedAssetsImage +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% center;background-size: 100% auto;background-color: transparent;border-radius: 8px;z-index:10001;'">
          </div>
        </div>
        <p class="blind-box-name">{{ selectItem.relatedAssetsName }}</p>
        <p class="blind-box-id">{{ selectItem.showId  && selectItem.showId != '0' ? selectItem.showId : selectItem.uniqueCode }}</p>
        <div class="blind-box-line"></div>
        <p class="blind-box-tip">藏品已存入账户中，请在“我的藏品”中查看</p>
      </div>
      <div
        v-if="showBoxDetail"
        style="position: absolute;top:468px;width:100%;text-align:center;z-index:10003;"
        @click="showBoxDetail = false"
      >
        <img
          style="width:36px;height:36px;"
          src="../assets/icon/blindBox/close.png"
        />
      </div>
    </div>
    <!-- 遮照 -->
    <div
      v-if="showBoxDetail"
      class="toast"
      @click="showBoxDetail = false"
    ></div>
    <!-- 背景图 -->
    <div
      v-if="showBoxDetail"
      :class="windowHeight <= 560 ? 'middle-mobile-bg' : 'nomal-mobile-bg'"
      :style="'width: 90vw;height: 438px;background-image: url('+ selectItem.relatedAssetsImage +');background-repeat: no-repeat;background-attachment: scroll;background-position: 50% center;background-size: 210% auto;background-color: transparent;border-radius: 8px;z-index:10001;'"
    ></div>
  </div>
</template>

<script>
import api from '../api/index-client'
export default {
  data () {
    return {
      list: [],
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      showBoxDetail: false,
      selectItem: {}
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      api
        .get('assets/box/record')
        .then(res => {
          if (res.status == 200) {
            this.list = res.data.data.content
          } else {
            this.$toast({
              message: res.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    toDetail (item) {
      this.selectItem = item
      this.showBoxDetail = true
    }
  }
}
</script>

<style lang="less" scoped>
.open-blind-box-record-container {
  margin-top: 3.875rem;
  .list {
    margin-top: 1.3125rem;
    .item {
      width: calc(94vw - 16px);
      margin-left: 3%;
      height: 32vw;
      background: #2c2c2c;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 16px 0 16px 16px;
      .item-icon {
        width: 30vw;
        height: 30vw;
        overflow: hidden;
        background: #ffffff;
        border-radius: 8px;
        position: relative;
        &-container {
          width: 30vw;
          height: 30vw;
          display: block;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
    }
    .infos {
      width: 50vw;
      margin-left: 0.9375rem;
      .name {
        font-size: 18px;
        font-family: lantingheiweight;
        font-weight: bold;
        color: #ffffff;
        line-height: 25px;
        width: 50vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 8px;
      }
      .boxNo,
      .openTime {
        font-size: 12px;
        font-family: lantingheiweight;
        font-weight: 500;
        color: #999999;
        line-height: 16px;
        width: 50vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 8px;
      }
      .detailButton {
        margin-top: 0.25rem;
        font-size: 0.75rem;
        font-family: lantingheiweight;
        font-weight: 600;
        background: #2c2c2c;
        color: #ffffff;
        line-height: 1.1875rem;
        width: 6.75rem;
        height: 1.75rem;
        border: 0.0625rem solid #ffffff;
        outline: none;
      }
    }
  }
  .epmty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 11.4375rem;
    img {
      width: 6.5rem;
      height: 5.9375rem;
    }
    p {
      margin-top: 1.375rem;
      font-size: 1rem;
      font-family: NotoSansHans-Regular, NotoSansHans;
      font-weight: 400;
      color: #666666;
    }
  }
  .toast {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000000;
    opacity: 0.75;
    top: 0;
    left: 0;
    z-index: 10000;
  }
  .blind-img-box {
    width: 252px;
    height: 252px;
    background: #ffffff;
    border-radius: 8px;
    img {
      width: 236px;
      height: 236px;
    }
  }
  .blind-box-name {
    width: 80vw;
    text-align: center;
    font-size: 22px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 19px;
  }
  .blind-box-id {
    width: 80vw;
    text-align: center;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 11px;
  }
  .blind-box-line {
    width: 80vw;
    height: 1px;
    border-bottom: 1px dashed #ffffff;
    opacity: 0.5;
    margin-top: 17px;
  }
  .blind-box-tip {
    width: 90vw;
    text-align: center;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 300;
    color: #ffffff;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    margin-top: 14px;
  }
  .middle-mobile-bg {
    position: fixed;
    left: 5vw;
    top: 10px;
  }
  .nomal-mobile-bg {
    position: fixed;
    left: 5vw;
    top: 60px;
  }
}
</style>